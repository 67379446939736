var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", xl: "4", lg: "4", md: "4" } },
            [
              _c(
                "b-card",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex justify-content-between flex-column",
                          attrs: { cols: "12", xl: "12", md: "12" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-center" },
                            [
                              _c("b-avatar", {
                                staticClass: "d-none d-sm-block mr-1",
                                attrs: {
                                  text: _vm.avatarText(_vm.teacher.full_name),
                                  variant: "light-primary",
                                  size: "104px",
                                  rounded: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column mt-2 mb-2" },
                            [
                              _c("h5", { staticClass: "mb-0 text-center" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.teacher.full_name) + " "
                                ),
                              ]),
                              _c(
                                "p",
                                { staticClass: "text-center mt-1 mb-0" },
                                [_vm._v(_vm._s(_vm.teacher.email))]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "12", xl: "12" } },
                        [
                          _c("ul", { staticClass: "list-unstyled mt-1 mb-0" }, [
                            _c("li", { staticClass: "pt-1 pb-0" }, [
                              _vm._v(" CPF "),
                              _c("span", { staticClass: "float-right" }, [
                                _vm._v(" " + _vm._s(_vm.teacher.cpf) + " "),
                              ]),
                            ]),
                            _c("li", { staticClass: "pt-1 pb-0" }, [
                              _vm._v(" WhatsApp "),
                              _c("span", { staticClass: "float-right" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.teacher.cellphone) + " "
                                ),
                              ]),
                            ]),
                            _c("li", { staticClass: "pt-1 pb-0" }, [
                              _vm._v(" Aulas "),
                              _c(
                                "span",
                                { staticClass: "float-right" },
                                [
                                  _c(
                                    "b-badge",
                                    { attrs: { variant: "light-primary" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.teacher.lessons
                                              ? _vm.teacher.lessons.length
                                              : 0
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("li", { staticClass: "pt-1 pb-0" }, [
                              _vm._v(" Materiais "),
                              _c(
                                "span",
                                { staticClass: "float-right" },
                                [
                                  _c(
                                    "b-badge",
                                    { attrs: { variant: "light-primary" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.teacher.medias
                                              ? _vm.teacher.medias.length
                                              : 0
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("li", { staticClass: "pt-1 pb-0" }, [
                              _vm._v(" Tags "),
                              _c(
                                "span",
                                { staticClass: "float-right" },
                                [
                                  _c(
                                    "b-badge",
                                    { attrs: { variant: "light-primary" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.teacher.tags
                                              ? _vm.teacher.tags.length
                                              : 0
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("li", { staticClass: "pt-1 pb-0" }, [
                              _vm._v(" Anotações "),
                              _c(
                                "span",
                                { staticClass: "float-right" },
                                [
                                  _c(
                                    "b-badge",
                                    { attrs: { variant: "light-primary" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.teacher.notes
                                              ? _vm.teacher.notes.length
                                              : 0
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("li", { staticClass: "pt-1 pb-0" }, [
                              _vm._v(" Cadastrado "),
                              _c("span", { staticClass: "float-right" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("datePT")(
                                        _vm.teacher.created_at,
                                        true
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-outline-danger",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmDelete(_vm.teacher.uuid)
                        },
                      },
                    },
                    [_vm._v(" Deletar ")]
                  ),
                ]),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-outline-dark",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: "teachers-edit",
                            params: { uuid: _vm.teacher.uuid },
                          })
                        },
                      },
                    },
                    [_vm._v(" Editar ")]
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", xl: "8", lg: "8", md: "8" } },
            [
              _c(
                "b-tabs",
                { attrs: { pills: "" } },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: { active: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c("feather-icon", {
                                staticClass: "mr-0 mr-sm-50",
                                attrs: { icon: "PlayIcon", size: "16" },
                              }),
                              _c(
                                "span",
                                { staticClass: "d-none d-sm-inline" },
                                [_vm._v("Aulas")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "b-card",
                        { attrs: { header: "Aulas" } },
                        [
                          _c("b-table", {
                            ref: "refCartListTable",
                            attrs: {
                              items: _vm.itemsLessons,
                              responsive: "",
                              fields: _vm.fieldsLessons,
                              "primary-key": "id",
                              "show-empty": "",
                              "empty-text": "Nenhum registro até o momento.",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "cell(title)",
                                fn: function (data) {
                                  return [
                                    _vm._v(" " + _vm._s(data.item.title) + " "),
                                  ]
                                },
                              },
                              {
                                key: "cell(price)",
                                fn: function (data) {
                                  return [
                                    _vm._v(" " + _vm._s(data.item.price) + " "),
                                  ]
                                },
                              },
                              {
                                key: "cell(action)",
                                fn: function (data) {
                                  return [
                                    _c("div", { staticClass: "form-row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-12" },
                                        [
                                          _c("feather-icon", {
                                            staticClass:
                                              "cursor-pointer cursor",
                                            attrs: {
                                              id:
                                                "order-" +
                                                data.item.id +
                                                "-preview-icon",
                                              icon: "EditIcon",
                                              size: "16",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$router.push({
                                                  name: "lessons-edit",
                                                  params: {
                                                    uuid: data.item.uuid,
                                                  },
                                                })
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c("feather-icon", {
                                staticClass: "mr-0 mr-sm-50",
                                attrs: { icon: "FileTextIcon", size: "16" },
                              }),
                              _c(
                                "span",
                                { staticClass: "d-none d-sm-inline" },
                                [_vm._v("Materiais")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "b-card",
                        { attrs: { header: "Materiais" } },
                        [
                          _c("b-table", {
                            ref: "refCartListTable",
                            attrs: {
                              items: _vm.itemsMedias,
                              responsive: "",
                              fields: _vm.fieldsMedias,
                              "primary-key": "id",
                              "show-empty": "",
                              "empty-text": "Nenhum registro até o momento.",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "cell(title)",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: data.item.url,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: "ExternalLinkIcon" },
                                        }),
                                        _vm._v(
                                          " " + _vm._s(data.item.title) + " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell(action)",
                                fn: function (data) {
                                  return [
                                    _c("div", { staticClass: "form-row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col-12" },
                                        [
                                          _c("feather-icon", {
                                            staticClass:
                                              "cursor-pointer cursor",
                                            attrs: {
                                              id:
                                                "order-" +
                                                data.item.id +
                                                "-preview-icon",
                                              icon: "EditIcon",
                                              size: "16",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$router.push({
                                                  name: "lessons-edit",
                                                  params: {
                                                    uuid: data.item.uuid,
                                                  },
                                                })
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c("feather-icon", {
                                staticClass: "mr-0 mr-sm-50",
                                attrs: { icon: "FileTextIcon", size: "16" },
                              }),
                              _c(
                                "span",
                                { staticClass: "d-none d-sm-inline" },
                                [_vm._v("Anotações")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "b-card",
                        { attrs: { header: "Histórico de anotações" } },
                        [
                          _c(
                            "b-form",
                            {
                              ref: "form",
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.submitNote.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v("Título"),
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.item.title,
                                          expression: "item.title",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid": _vm.$v.item.title.$error,
                                      },
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.item.title },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.item,
                                            "title",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _c("div", { staticClass: "col-12" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.item.description,
                                          expression: "item.description",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid":
                                          _vm.$v.item.description.$error,
                                      },
                                      attrs: {
                                        placeholder: "Escreva aqui...",
                                        cols: "30",
                                        rows: "5",
                                      },
                                      domProps: { value: _vm.item.description },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.item,
                                            "description",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-12" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(255, 255, 255, 0.15)",
                                            expression:
                                              "'rgba(255, 255, 255, 0.15)'",
                                            modifiers: { 400: true },
                                          },
                                        ],
                                        attrs: {
                                          size: "sm",
                                          variant: "success",
                                          type: "submit",
                                        },
                                      },
                                      [
                                        _vm.submitedNote
                                          ? _c("b-spinner", {
                                              attrs: {
                                                label: "Loading...",
                                                small: "",
                                              },
                                            })
                                          : _c("span", [_vm._v("Cadastrar")]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _vm.teacher.notes
                            ? _c(
                                "app-timeline",
                                { staticClass: "mt-2" },
                                _vm._l(
                                  _vm.teacher.notes,
                                  function (note, index) {
                                    return _c(
                                      "app-timeline-item",
                                      {
                                        key: index,
                                        attrs: {
                                          icon: !note.file
                                            ? "chat-square-dots"
                                            : "paperclip",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0",
                                          },
                                          [
                                            _c("h6", [
                                              _vm._v(
                                                " " + _vm._s(note.title) + " "
                                              ),
                                            ]),
                                            _c(
                                              "small",
                                              { staticClass: "text-dark" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("datePT")(
                                                        note.created_at,
                                                        true
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("p", { staticClass: "mt-1" }, [
                                          _vm._v(
                                            " " + _vm._s(note.description) + " "
                                          ),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c("feather-icon", {
                                staticClass: "mr-0 mr-sm-50",
                                attrs: { icon: "HashIcon", size: "16" },
                              }),
                              _c(
                                "span",
                                { staticClass: "d-none d-sm-inline" },
                                [_vm._v("Tags")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "b-card",
                        { attrs: { header: "Tags" } },
                        _vm._l(_vm.teacher.tags, function (tag) {
                          return _c(
                            "b-badge",
                            {
                              key: tag.code,
                              staticStyle: { "margin-right": "1px" },
                              attrs: { pill: "", variant: "light-primary" },
                            },
                            [_vm._v(" " + _vm._s(tag.title) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }