<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
      >
        <b-card>
          <b-row>
            <!-- User Info: Left col -->
            <b-col
              cols="12"
              xl="12"
              md="12"
              class="d-flex justify-content-between flex-column"
            >
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex justify-content-center">
                <b-avatar
                  :text="avatarText(teacher.full_name)"
                  variant="light-primary"
                  size="104px"
                  class="d-none d-sm-block mr-1"
                  rounded
                />
              </div>
              <div class="d-flex flex-column mt-2 mb-2">
                <h5 class="mb-0 text-center">
                  {{ teacher.full_name }}
                </h5>
                <p class="text-center mt-1 mb-0">{{ teacher.email }}</p>
              </div>
            </b-col>
            <!-- Right Col: Table -->
            <b-col
              cols="12"
              md="12"
              xl="12"
            >
              <ul class="list-unstyled mt-1 mb-0">
                <li class="pt-1 pb-0">
                  CPF
                  <span class="float-right">
                    {{ teacher.cpf }}
                  </span>
                </li>
                <li class="pt-1 pb-0">
                  WhatsApp
                  <span class="float-right">
                    {{ teacher.cellphone }}
                  </span>
                </li>
                <li class="pt-1 pb-0">
                  Aulas
                  <span class="float-right">
                    <b-badge variant="light-primary">
                      {{ teacher.lessons ? teacher.lessons.length : 0 }}
                    </b-badge>
                  </span>
                </li>
                <li class="pt-1 pb-0">
                  Materiais
                  <span class="float-right">
                    <b-badge variant="light-primary">
                      {{ teacher.medias ? teacher.medias.length : 0 }}
                    </b-badge>
                  </span>
                </li>
                <li class="pt-1 pb-0">
                  Tags
                  <span class="float-right">
                    <b-badge variant="light-primary">
                      {{ teacher.tags ? teacher.tags.length : 0 }}
                    </b-badge>
                  </span>
                </li>
                <li class="pt-1 pb-0">
                  Anotações
                  <span class="float-right">
                    <b-badge variant="light-primary">
                      {{ teacher.notes ? teacher.notes.length : 0 }}
                    </b-badge>
                  </span>
                </li>
                <li class="pt-1 pb-0">
                  Cadastrado
                  <span class="float-right">
                    {{ teacher.created_at | datePT(true) }}
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-card>
        <div class="form-row">
          <div class="col-6">
            <button
              @click="confirmDelete(teacher.uuid)"
              type="button"
              class="btn btn-block btn-outline-danger">
              Deletar
            </button>
          </div>
          <div class="col-6">
            <button
              @click="$router.push({
                name: 'teachers-edit',
                params: { uuid: teacher.uuid },
              })"
              type="button"
              class="btn btn-block btn-outline-dark">
                Editar
            </button>
          </div>
        </div>
        
      </b-col>

      <b-col
        cols="12"
        xl="8"
        lg="8"
        md="8"
      >
        <b-tabs
          pills
        >
          <b-tab active>
            <template #title>
              <feather-icon
                icon="PlayIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Aulas</span>
            </template>
            <b-card
              header="Aulas"
            >
              <b-table
                ref="refCartListTable"
                :items="itemsLessons"
                responsive
                :fields="fieldsLessons"
                primary-key="id"
                show-empty
                empty-text="Nenhum registro até o momento."
              >
                <template #cell(title)="data">
                  {{ data.item.title }}
                </template>

                <template #cell(price)="data">
                  {{ data.item.price }}
                </template>

                <template #cell(action)="data">
                  <div class="form-row">
                    <div class="col-12">
                      <feather-icon
                        :id="`order-${data.item.id}-preview-icon`"
                        icon="EditIcon"
                        size="16"
                        class="cursor-pointer cursor"
                        @click="$router.push({ name: 'lessons-edit', params: { uuid: data.item.uuid }})"
                      />
                    </div>
                  </div>
                </template>
              </b-table>
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon
                icon="FileTextIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Materiais</span>
            </template>
            <b-card
              header="Materiais"
            >
              <b-table
                ref="refCartListTable"
                :items="itemsMedias"
                responsive
                :fields="fieldsMedias"
                primary-key="id"
                show-empty
                empty-text="Nenhum registro até o momento."
              >
                <template #cell(title)="data">
                  <a :href="data.item.url" target="_blank">
                    <feather-icon
                      icon="ExternalLinkIcon" />
                    {{ data.item.title }}
                  </a>
                </template>

                <template #cell(action)="data">
                  <div class="form-row">
                    <div class="col-12">
                      <feather-icon
                        :id="`order-${data.item.id}-preview-icon`"
                        icon="EditIcon"
                        size="16"
                        class="cursor-pointer cursor"
                        @click="$router.push({ name: 'lessons-edit', params: { uuid: data.item.uuid }})"
                      />
                    </div>
                  </div>
                </template>
              </b-table>
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon
                icon="FileTextIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Anotações</span>
            </template>
            <b-card header="Histórico de anotações">
              <b-form
                ref="form"
                @submit.prevent="submitNote"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="">Título</label>
                      <input
                        :class="{ 'is-invalid': $v.item.title.$error }"
                        type="text"
                        class="form-control"
                        v-model="item.title">
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <textarea
                        v-model="item.description"
                        placeholder="Escreva aqui..."
                        class="form-control"
                        :class="{ 'is-invalid': $v.item.description.$error }"
                        cols="30"
                        rows="5"></textarea>
                    </div>
                  </div>
                  <div class="col-12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="success"
                      type="submit"
                    >
                      <b-spinner label="Loading..." small v-if="submitedNote" />
                      <span v-else>Cadastrar</span>
                    </b-button>
                  </div>
                </div>
              </b-form>

              <app-timeline v-if="teacher.notes" class="mt-2">
                <app-timeline-item v-for="(note, index) in teacher.notes" :key="index" :icon="!note.file ? 'chat-square-dots' : 'paperclip'">
                  <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <h6>
                      {{ note.title }}
                    </h6>
                    <small class="text-dark">
                      {{ note.created_at | datePT(true) }}
                    </small>
                  </div>
                  <p class="mt-1">
                    {{ note.description }}
                  </p>
                </app-timeline-item>
              </app-timeline>
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon
                icon="HashIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Tags</span>
            </template>
            <b-card header="Tags">
              <b-badge v-for="tag in teacher.tags" :key="tag.code" pill variant="light-primary" style="margin-right: 1px">
                {{ tag.title }}
              </b-badge>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BAlert, BLink, BCard, BAvatar, BButton, BCardHeader, BCardBody, BTable, BTooltip,
  BBadge, BTab, BTabs, BCardText, BCardTitle, BMediaBody, VBTooltip, BSpinner, BForm,
} from 'bootstrap-vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import Ripple from 'vue-ripple-directive';
import { mapState } from "vuex";
import { avatarText } from '@core/utils/filter'

import { required } from "vuelidate/lib/validators";

export default {
  setup() {
    return {
      avatarText
    }
  },
  components: {
    AppTimeline,
    BForm,
    BSpinner,
    AppTimelineItem,
    BCardText,
    BMediaBody,
    BCardTitle,
    BRow,
    BCardHeader,
    BTable,
    BTab,
    BTabs,
    BBadge,
    BCardBody,
    BAvatar,
    BCol,
    BAlert,
    BLink,
    BCard,
    BButton,
    BTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      item: {
        title: '',
        description: '',
        teacher_uuid: this.$route.params.uuid,
      },
      submitedNote: false,
      fieldsMedias: [
        {
          key: 'title',
          label: 'Título',
          sortable: false,
        },
        {
          key: "action",
          label: "",
          class: "text-center",
          thStyle: "width: 80px",
          sortable: false,
        },
      ],
      fieldsLessons: [
        {
          key: 'title',
          label: 'Produto',
          sortable: false,
        },
        {
          key: 'action',
          label: '',
          class: 'text-center',
          thStyle: 'width: 30px',
        },
      ],
      itemsLessons: [],
      itemsMedias: [],
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    ...mapState('Teacher', ['teacher', 'loading']),
  },
  validations: {
    item: {
      title: {
        required,
      },
      description: {
        required,
      },
      teacher_uuid: {
        required,
      },
    }
  },
  methods: {
    submitNote() {
      this.$v.$touch();
      this.submitedNote = true;

      if (!this.$v.$error) {
       
        this.$store.dispatch('TeacherNotes/create', this.item).then((res) => {
          this.getData();
          this.notify(
            'Operação realizada com sucesso!',
            'UserCheckIcon',
            'success',
          );
        })
        .catch(() => {
          this.submitedNote = false;
        })
        .finally(() => {
          this.submitedNote = false;
        });
      } else {
        this.submitedNote = false;
        this.notifyDefault("error", 'Verifique os campos obrigatórios');
      }
    },
    confirmDelete(uuid) {
      this.$swal({
        title: 'Tem certeza?',
        text: "Essa ação será irreversível ",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero deletar!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch("Teacher/delete", { uuid: uuid })
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Deletado!',
                text: 'Operação realizada com sucesso.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              });

              this.$router.push({ name: 'teachers-list' });
            })
        }
      })
    },
    async getData() {
      this.$store.dispatch('Teacher/show', this.$route.params.uuid)
      .then(() => {
        this.itemsLessons = this.teacher.lessons;
        this.itemsMedias = this.teacher.medias;
      })
    }
  }
};
</script>

<style scoped lang="scss">
@media (max-width: 480px) {
  .tabs {
    margin-top: 30px;
  }
}
</style>
